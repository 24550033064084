import React, { ReactElement } from 'react';
import { SxStyleProp } from 'theme-ui';

import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { ToggleSelection } from '@UX/Selectable/ToggleSelection';

type TabListItemStyle = {
  tabStyles: SxStyleProp;
  titleStyles: SxStyleProp;
};

const tabListItemLinkStyles = (
  accordion?: boolean,
  verticalTabUnit?: boolean,
): TabListItemStyle => {
  if (verticalTabUnit) {
    return {
      tabStyles: {
        display: 'block',
        color: 'verticalTabText',
        textDecoration: 'none',
        fontSize: accordion ? 'l' : ['s', 'm'],
        borderStyle: 'solid',
        borderColor: 'verticalTabBorder',
        backgroundColor: 'verticalTabBackground',
        borderBottomColor: 'verticalTabBorder',
        marginRight: 1,

        width: '100%',

        ...(!accordion
          ? {
              paddingY: '3xs',
              paddingX: ['3xs', 'xs'],
              height: '100%',
              borderWidth: 'outlinedStrokeWeight',
              borderTopWidth: 0,
            }
          : {}),

        ...(accordion
          ? {
              display: ['flex', 'none'],
              padding: 'xs',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTopWidth: 'outlinedStrokeWeight',
            }
          : {}),

        '&[aria-expanded=true]': {
          backgroundColor: 'verticalTabActiveBackground',
          borderBottomColor: 'verticalTabBorder',
          ...(!accordion && {
            borderLeftColor: 'tagprimaryDefault',
            borderLeftWidth: 'focusStrokeWeight',
            borderRightWidth: 0,
          }),
          ...(accordion && {
            borderLeftColor: ['verticalTabBorder', 'transparent'],
            borderTopWidth: ['focusStrokeWeight', 0],
            borderTopColor: 'verticalTabActiveBorder',
          }),
        },

        '&:hover, :visited': {
          color: 'verticalTabText',
        },
      },
      titleStyles: accordion
        ? {
            flex: '1',
          }
        : {},
    };
  }

  return {
    tabStyles: {
      flex: '1 1 auto',
      display: 'block',
      color: 'tabText',
      textDecoration: 'none',
      fontSize: accordion ? 'l' : ['s', 'm'],
      borderStyle: 'solid',
      borderColor: 'tabText',
      marginRight: 1,

      backgroundColor: 'tabBackground',
      borderBottomColor: 'tabText',

      ...(!accordion
        ? {
            paddingY: '3xs',
            paddingX: ['3xs', 'xs'],
            height: '100%',
          }
        : {}),

      ...(accordion
        ? {
            display: ['block', 'none'],
            padding: 'xs',
          }
        : {}),

      '&[aria-expanded=true]': {
        backgroundColor: 'tabActiveBackground',
        borderBottomColor: 'tabActiveBackground',
      },

      '&:hover, :visited': {
        color: 'tabText',
      },
    },
    titleStyles: {},
  };
};

function preventDefault<E extends React.SyntheticEvent>(e: E) {
  e.preventDefault();
}

interface TabListItemLinkProps {
  id?: string;
  prefix: string;
  tab: ReactElement;
  tabId?: string;
  index: number | string;
  accordion?: boolean;
  verticalTabUnit?: boolean;
  tabLinkStyle?: SxStyleProp;
  onTabChanged?: (tabId: string) => void;
}

export const TabListItemLink: React.FC<TabListItemLinkProps> = ({
  id,
  prefix,
  index,
  tab,
  tabId,
  accordion,
  verticalTabUnit = false,
  onTabChanged,
  tabLinkStyle = {},
}) => {
  const controls = `tabpanel_${prefix}_${index}`;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    preventDefault(e);
    const element = document.querySelector(`[aria-controls="${controls}"]:not([id])`);
    setTimeout(
      () =>
        element?.scrollIntoView({
          behavior: getScrollBehavior(),
          block: 'start',
        }),
      50,
    );
    if (onTabChanged && tabId) {
      onTabChanged(tabId);
    }
  };

  const { tabStyles, titleStyles } = tabListItemLinkStyles(accordion, verticalTabUnit);

  return (
    <WithClickTracking
      action={prefix}
      label={tab.props.title}
      useDataTracking={false}
    >
      <ToggleSelection
        id={id}
        as="a"
        role="tab"
        controls={controls}
        expands
        onClick={handleClick}
        showChevrons={accordion}
        sx={{ ...tabStyles, ...tabLinkStyle }}
      >
        <span sx={titleStyles}>{tab.props.title}</span>
      </ToggleSelection>
    </WithClickTracking>
  );
};
